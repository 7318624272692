import { isEmpty } from 'lodash';
import * as State from 'Domain/version/web/core/State';
import Objects from 'Helper/object/Object';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';
import { Providers } from 'Domain/version/web/config/Providers';
import * as Provider from 'Domain/version/web/core/Provider';
import ninjaMetrics from 'Helper/metrics/NinjaMetrics';
import { Slot } from 'Types/Slot';

export const defaultDivId = 'baxter-top-level';

const MODAL_CLASS_NAME = 'baxter-interstitial-modal';

const excludeForProvider = (config, id) => {
  const providerSettings = config.slots?.providerSettings?.[id].interstitial as
    | Record<string, Record<string, { enabled: boolean } | undefined>>
    | Record<string, { enabled: boolean } | undefined>
    | unknown;
  return !(
    Object.values((providerSettings as Record<string, Record<string, { enabled: boolean }>>)?._ || {}).find(
      (item) => Objects.isObject(item) && item?.enabled
    ) || Object.values(providerSettings || {}).find((item) => Objects.isObject(item) && item?.enabled)
  );
};

export const webpackExclude = (config) => excludeForProvider(config, Providers.GOOGLE_ADS);

let interval;
let timeout;
const targetPages = {
  home: 'homepage',
  listing: 'listing',
  advertPage: 'ad',
  ad: 'ad',
  ads: 'ads',
  account: 'account',
  adding: 'adding',
  bundles: 'bundles',
  myaccount: 'myaccount',
  pro: 'pro',
  page1: 'page1',
  page2: 'page2',
};

const validPathNames = (slot: Slot, targetPage: string) => {
  const validPathName = slot.pageId === State.getPageId() && slot.interstitial?.targetPage === targetPage;
  if (!validPathName) {
    newRelicMetrics.reportMetric(BaxterMetric.INTERSTITIAL_INVALID_PATHNAME, {
      slotPage: slot.pageId,
      slotTarget: slot.interstitial?.targetPage,
      targetPageId: targetPage,
      pageId: slot.pageId,
    });
  }
  return validPathName;
};

/**
 * Check if the modal height is less than the viewport height -54px for the close button.
 * @param slotContainerId
 * @returns {boolean}
 */
const validSizeContainer = (slotContainerId: string) => {
  // 54 default height of baxter-interstitial-modal-footer
  const modal = document.getElementById(slotContainerId);
  const slotHeight = modal
    ? (Number(modal.getElementsByTagName('iframe')?.[0]?.getAttribute('height')) || 0) + 54
    : document.getElementsByClassName(MODAL_CLASS_NAME)?.[0]?.clientHeight;
  const isValidSize = slotHeight <= window.innerHeight;
  if (!isValidSize) {
    newRelicMetrics.reportMetric(BaxterMetric.INTERSTITIAL_INVALID_SIZE, {
      slotHeight,
      slotContainerId,
      clientHeight: window.innerHeight,
      pageId: State.getPageId(),
    });
  }
  return isValidSize;
};

const slotModalContainerId = (innerId: string) => `${innerId}-modal-container`;

const validSize = (slot: Slot) => validSizeContainer(slotModalContainerId(slot.innerId));

const resolveActions = async () => {
  console.info('[SLOTS][INTERSTITIAL][RESOLVEACTIONS]');
  for (const action of State.getInterstitialActions()) {
    // eslint-disable-next-line no-await-in-loop
    await action();
  }
  State.clearInterstitialActions();
};

const showCloseTimer = (slot: Slot) => {
  console.info('[SLOTS][INTERSTITIAL][SHOWCLOSETIMER]', slot);
  let autoClose = (slot.interstitial?.autoClose || 1) - 1;
  interval = setInterval(() => {
    try {
      if (autoClose > 0) {
        const element = document.getElementById(`baxter-interstitial-modal-footer-countdown-${slot.innerId}`);
        if (element) element.innerHTML = `${autoClose}s`;
        autoClose -= 1;
      }
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][SHOWCLOSETIMER]', e);
      newRelicMetrics.reportError(BaxterError.INTERSTITIAL_SHOW_CLOSE_TIMER_ERROR, { message: (e as Error).message });
    }
  }, 1000);
};

export const removeModal = (innerId: string) => {
  console.info('[SLOTS][INTERSTITIAL][REMOVEMODAL]', innerId);
  clearInterval(interval);
  clearTimeout(timeout);
  document.body.classList.remove('baxter-interstitial-overflow-hidden');
  document.getElementById(slotModalContainerId(innerId))?.remove();
};

const closeModal = (innerId) => {
  console.info('[SLOTS][INTERSTITIAL][CLOSEMODAL]', innerId);
  newRelicMetrics.reportMetric(BaxterMetric.INTERSTITIAL_CLOSE_MODAL, {
    pageId: State.getPageId(),
  });
  removeModal(innerId);
  setTimeout(async () => {
    try {
      await resolveActions();
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][CLOSEMODAL][TIMEOUT]', e);
      newRelicMetrics.reportError(BaxterError.INTERSTITIAL_CLOSE_MODAL_TIMEOUT_ERROR, {
        message: (e as Error).message,
      });
    }
  }, 1);
};

const createModalDiv = (slot) => {
  console.info('[SLOTS][INTERSTITIAL][CREATEMODALDIV]', slot);
  newRelicMetrics.reportMetric(BaxterMetric.INTERSTITIAL_CREATE_MODAL, {
    pageId: State.getPageId(),
    slotId: slot.id,
    targetPage: slot.targetPage,
  });
  const modal = document.createElement('div');
  modal.setAttribute('id', slotModalContainerId(slot.innerId));
  modal.setAttribute('style', 'display:none');
  modal.setAttribute('class', 'baxter-interstitial');
  modal.innerHTML = `<div class="${MODAL_CLASS_NAME}">
        <div id="baxter-interstitial-modal-content" class="baxter-interstitial-modal-content"></div>
        <div class="baxter-interstitial-modal-footer">
            <div class="baxter-interstitial-modal-footer-content">
                <div class="baxter-interstitial-modal-footer-content-title">
                    ${slot.interstitial.modalTitle}
                </div>
                <small class="baxter-interstitial-modal-footer-content-subtitle${slot.interstitial.autoClose ? '' : '--hidden'}">
                    ${slot.interstitial.autoCloseText}&nbsp;
                    <span id="baxter-interstitial-modal-footer-countdown-${slot.innerId}"></span>
                </small>
            </div>
            <button class="baxter-interstitial-modal-footer-close-btn">
                <span class="baxter-interstitial-modal-footer-close-btn-title">
                    ${slot.interstitial.closeButtonTitle}&nbsp;
                    <span class="baxter-interstitial-modal-footer-close-btn-title-icon">&times;</span>
                </span>
            </button>
        </div>
    </div>`;

  modal.getElementsByClassName('baxter-interstitial-modal-footer-close-btn')?.[0].addEventListener('click', () => {
    try {
      closeModal(slot.innerId);
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][CLOSECLICKHANDLER]', e);
      newRelicMetrics.reportError(BaxterError.INTERSTITIAL_CLOSE_CLICK_HANDLER_ERROR, {
        message: (e as Error).message,
      });
    }
  });
  return modal;
};

const setAutoClose = (slot) => {
  console.info('[SLOTS][INTERSTITIAL][SETAUTOCLOSE]', slot);
  // convert seconds to milliseconds
  const autoCloseInMs = slot.interstitial.autoClose * 1000;
  if (autoCloseInMs) {
    console.debug('[SLOTS][INTERSTITIAL][SETAUTOCLOSE]', autoCloseInMs);
    showCloseTimer(slot);
    timeout = setTimeout(() => {
      try {
        closeModal(slot.innerId);
      } catch (e) {
        console.error('[SLOTS][INTERSTITIAL][SETAUTOCLOSE][TIMEOUT]', e);
        newRelicMetrics.reportError(BaxterError.INTERSTITIAL_SET_AUTO_CLOSE_TIMEOUT_ERROR, {
          message: (e as Error).message,
        });
      }
    }, autoCloseInMs);
  }
};

export const interstitialAbTests = {
  INTERSTITIAL_AD_WITH_PRELOADING: 'euads-6137@a',
  INTERSTITIAL_AD_WITHOUT_PRELOADING: 'euads-6137@b',
};

export const storeInState = (slot: Slot) => {
  console.info('[SLOTS][INTERSTITIAL][STOREINSTATE]', slot);
  const slotId = `${slot.pageId}#${slot.interstitial?.targetPage}`;
  State.setInterstitialSlot(slotId, slot);
  ninjaMetrics.reportMetric('interstitial_preloaded', { ad_unit_id: slot.path });
  console.debug('[SLOTS][INTERSTITIAL][STOREINSTATE] interstitial_preloaded event', slot.path);
};

const convertMinutesToMilliseconds = (minutes: number) => minutes * 60000;

export const validFrequencyCap = (slot: Slot, slotKey: string) => {
  const frequencyCapInMs = convertMinutesToMilliseconds(slot.interstitial?.frequencyCap || 1);
  const lastRendering = Number(localStorage.getItem(`interstitial_ad_${slotKey}`)) || 0;
  return Date.now() > lastRendering + frequencyCapInMs;
};

export const readyToRender = (slotId: string, targetPage: string) => {
  console.info('[SLOTS][INTERSTITIAL][READYTORENDER]', slotId, targetPage);
  const slot = State.getInterstitialSlot(slotId);
  return (
    !isEmpty(slot) &&
    slot?.interstitial &&
    validPathNames(slot, targetPage) &&
    validFrequencyCap(slot, slotId) &&
    validSize(slot)
  );
};

export const showModal = async (slotId: string) => {
  const { INTERSTITIAL_AD_WITH_PRELOADING, INTERSTITIAL_AD_WITHOUT_PRELOADING } = interstitialAbTests;

  const interstitialSlot = State.getInterstitialSlot(slotId);
  if (interstitialSlot) {
    const { abTest } = interstitialSlot.params;

    console.info('[SLOTS][INTERSTITIAL][SHOWMODAL]', slotId);
    newRelicMetrics.reportMetric(BaxterMetric.INTERSTITIAL_SHOW_MODAL, {
      pageId: State.getPageId(),
      slotKey: slotId,
    });

    document.body.classList.add('baxter-interstitial-overflow-hidden');
    const modal = document.getElementById(slotModalContainerId(interstitialSlot.innerId));

    const manageModalRendering = () => {
      setAutoClose(interstitialSlot);
      localStorage.setItem(`interstitial_ad_${slotId}`, Date.now().toString());
      State.removeInterstitialSlot(slotId);

      ninjaMetrics.reportMetric('interstitial_rendered', { ad_unit_id: interstitialSlot.path });
      console.debug('[SLOTS][INTERSTITIAL][SHOWMODAL] interstitial_rendered event', interstitialSlot.path);
    };

    if (modal) {
      modal.style.removeProperty('display');
    }

    if (abTest.includes(INTERSTITIAL_AD_WITH_PRELOADING)) {
      manageModalRendering();
    } else if (abTest.includes(INTERSTITIAL_AD_WITHOUT_PRELOADING)) {
      try {
        await Provider.Providers.find(({ id }) => id === interstitialSlot.provider)?.refresh([interstitialSlot]);
        manageModalRendering();
      } catch (e) {
        closeModal(interstitialSlot.innerId);

        console.error('[SLOTS][INTERSTITIAL][LOADCONTENT]', e);
        newRelicMetrics.reportError(BaxterError.INTERSTITIAL_LOAD_ERROR, { message: (e as Error).message });
      }
    }
  }
};

export const removeInterstitialModal = () => {
  console.info('[SLOTS][INTERSTITIAL][REMOVEINTERSTITIALMODAL]');
  const [modal] = document.getElementsByClassName(MODAL_CLASS_NAME);
  const innerId = modal?.children?.[0]?.id;
  if (innerId) removeModal(innerId);
};

export const getTargetPage = (pagePath: string) => {
  console.info('[SLOTS][INTERSTITIAL][GETTARGETPAGE]', pagePath);
  const page = pagePath.substring(0, pagePath.indexOf('-'));
  return targetPages[page];
};

export const isVisible = (innerId?: string) => {
  if (innerId) {
    // Get parent element of parent element which is the modal container
    const modal = document.getElementById(innerId)?.parentElement;
    if (modal?.parentElement?.style?.display !== 'none') {
      return true;
    }
    return false;
  }
  const parent = document.getElementById(defaultDivId);
  if (!parent) {
    return false;
  }
  return [...parent.children].some((child) => (child as HTMLElement).style.display !== 'none');
};

/**
 * Close the modal if the size is invalid. This is used if the mobile view is changed from portrait to landscape.
 *
 */
export const closeModalsWithInvalidSize = () => {
  console.info('[SLOTS][INTERSTITIAL][CLOSEMODALSWITHINVALIDSIZE]');
  const topLevelDiv = document.getElementById(defaultDivId);
  if (!topLevelDiv) {
    return;
  }
  const modals = topLevelDiv.getElementsByClassName(MODAL_CLASS_NAME);
  Array.from(modals).forEach((modal) => {
    const innerId = modal.getElementsByTagName('div')[0].id;
    if (isVisible(innerId) && !validSizeContainer(innerId)) {
      closeModal(innerId);
    }
  });
};

export const createDiv = (divId = defaultDivId) => {
  console.info('[SLOTS][INTERSTITIAL][CREATEDIV]', divId);
  const div = document.createElement('div');
  div.setAttribute('id', divId);
  div.setAttribute('style', 'display: fixed;');
  document.body.appendChild(div);
};

export const bootstrap = () => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
      try {
        console.info('[SLOTS][INTERSTITIAL][BOOTSTRAP][DOMCONTENTLOADEDEVENTLISTENER]');
        createDiv();
      } catch (e) {
        console.error('[SLOTS][INTERSTITIAL][BOOTSTRAP][DOMCONTENTLOADEDEVENTLISTENER]', e);
        newRelicMetrics.reportError(BaxterError.INTERSTITIAL_BOOTSTRAP_LISTENER_ERROR, {
          message: (e as Error).message,
        });
      }
    });
  } else {
    console.info('[SLOTS][INTERSTITIAL][BOOTSTRAP]');
    createDiv();
  }
};

export const clear = (divId = defaultDivId) => {
  console.info('[SLOTS][INTERSTITIAL][CLEAR]', divId);
  const div = document.getElementById(divId);
  if (div) {
    div.innerHTML = '';
  }
};

export const insertModal = (pageId: string, slot: Slot, divId = defaultDivId) => {
  console.info('[SLOTS][INTERSTITIAL][INSERTMODAL]', pageId, slot, divId);
  const modal = createModalDiv(slot);
  document.getElementById(divId)?.appendChild(modal);
  const slotInnerElement = document.getElementById(slot.innerId);
  const modalContent = document.getElementById('baxter-interstitial-modal-content');
  if (slotInnerElement && modalContent) {
    modalContent.appendChild(slotInnerElement);
  }
  newRelicMetrics.reportMetric(BaxterMetric.INTERSTITIAL_INSERT_MODAL, {
    pageId,
    slotId: slot.id,
  });
};
