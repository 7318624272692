import * as State from 'Domain/version/web/core/State';
import * as Cookie from 'Helper/browser/Cookie';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';
import * as LifeCycle from 'Domain/version/web/core/Lifecycle';

export const webpackExclude = (config) => !config.app?.consent?.enabled;

const consentQueue: { cmd: () => Promise<void>; name: string }[] = [];

const bootstrap = () => {
  removeLegacyCookie();
};

const removeLegacyCookie = () => {
  const name = 'OTAdditionalConsentString';
  if (Cookie.get(name)) {
    Cookie.remove(name);
  }
};

const setUserConsent = async () => {
  console.info('[SLOTS][CONSENT][SETUSERCONSENT]');
  State.setUserConsent(true);
  if (globalThis.googletag && globalThis.OptanonActiveGroups) {
    globalThis.googletag.cmd.push(() => {
      try {
        if (globalThis.OptanonActiveGroups.match(/STACK42,/) || globalThis.OnetrustActiveGroups.match(/C0004,/)) {
          console.debug(
            '[SLOTS][CONSENT][SETUSERCONSENT] googletag.pubads().setPrivacySettings({ restrictDataProcessing: false });'
          );
          newRelicMetrics.reportMetric(BaxterMetric.CONSENT_DONT_RESTRICT_DATA_PROCESSING, {});
          globalThis.googletag.pubads().setPrivacySettings({ restrictDataProcessing: false });
        } else {
          console.debug(
            '[SLOTS][CONSENT][SETUSERCONSENT] googletag.pubads().setPrivacySettings({ restrictDataProcessing: true });'
          );
          newRelicMetrics.reportMetric(BaxterMetric.CONSENT_RESTRICT_DATA_PROCESSING, {});
          globalThis.googletag.pubads().setPrivacySettings({ restrictDataProcessing: true });
        }
        processQueue();
      } catch (e) {
        console.error('[SLOTS][CONSENT][SETUSERCONSENT]', e);
        newRelicMetrics.reportError(BaxterError.GOOGLEADS_COMMAND_ERROR, {
          message: (e as Error).message,
          command: 'googletag.pubads().setPrivacySettings',
        });
        throw e;
      }
    });
  } else {
    if (!window.googletag) {
      console.debug('[SLOTS][CONSENT][SETUSERCONSENT] missing googletag');
      newRelicMetrics.reportMetric(BaxterMetric.CONSENT_MISSING_GOOGLE_TAG, {});
    } else {
      console.debug('[SLOTS][CONSENT][SETUSERCONSENT] missing optanon active groups');
      newRelicMetrics.reportMetric(BaxterMetric.CONSENT_MISSING_OPTANON_ACTIVE_GROUPS, {});
    }
    await processQueue();
  }
};

const isPending = () => !State.getUserConsent();

const delayLoad = () => {
  if (!State.getUserConsent()) {
    addToQueue('load', async () => {
      await LifeCycle.onLoad();
    });
    return true;
  }

  if (!emptyQueue()) {
    addToQueue('load', async () => {
      await LifeCycle.onLoad();
    });
    return true;
  }

  return false;
};

const addToQueue = (name, cmd) => {
  if (typeof cmd === 'function') {
    console.debug('[SLOTS][CONSENT][ADDTOQUEUE] PUSH', name, cmd);
    consentQueue.push({ name, cmd });
  }
};

const emptyQueue = () => consentQueue.length === 0;

const processQueue = async () => {
  for (; consentQueue.length; ) {
    const next = consentQueue.shift();
    if (!next) return;

    if (next.name === 'set') {
      console.debug('[SLOTS][CONSENT][PROCESSQUEUE] CALL', next.name, next.cmd);
      // eslint-disable-next-line no-await-in-loop
      await next.cmd.call(undefined);
    }
    if (next.name === 'load') {
      if (!consentQueue.length) {
        console.debug('[SLOTS][CONSENT][PROCESSQUEUE] CALL', next.name, next.cmd);
        // eslint-disable-next-line no-await-in-loop
        await next.cmd.call(undefined);
      } else {
        console.debug('[SLOTS][CONSENT][PROCESSQUEUE] PUSH', next.name, next.cmd);
        consentQueue.push(next);
      }
    }
  }
};

export default {
  bootstrap,
  setUserConsent,
  isPending,
  delayLoad,
  addToQueue,
};
